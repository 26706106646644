var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-selector" },
    [
      _c("sw-select", {
        ref: "baseSelect",
        staticClass: "multi-select-item",
        attrs: {
          options: _vm.customers,
          "show-labels": false,
          "preserve-search": false,
          placeholder: _vm.$t("customers.type_or_click"),
          label: "name",
        },
        on: {
          close: _vm.checkCustomers,
          value: _vm.onTextChange,
          select: (val) => _vm.$emit("select", val),
          remove: _vm.deselectCustomer,
        },
        model: {
          value: _vm.customerSelect,
          callback: function ($$v) {
            _vm.customerSelect = $$v
          },
          expression: "customerSelect",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }