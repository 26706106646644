var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-page", { staticClass: "relative" }, [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendData.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "sw-page-header",
          { staticClass: "mb-5", attrs: { title: _vm.pageTitle } },
          [
            _c(
              "sw-breadcrumb",
              { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
              [
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$t("general.home"),
                    to: "/admin/dashboard",
                  },
                }),
                _vm._v(" "),
                _c("sw-breadcrumb-item", {
                  attrs: {
                    title: _vm.$tc("expenses.expense", 2),
                    to: "/admin/expenses",
                  },
                }),
                _vm._v(" "),
                _vm.$route.name === "expenses.edit"
                  ? _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.edit_expense"),
                        to: "#",
                        active: "",
                      },
                    })
                  : _c("sw-breadcrumb-item", {
                      attrs: {
                        title: _vm.$t("expenses.new_expense"),
                        to: "#",
                        active: "",
                      },
                    }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "template",
              { slot: "actions" },
              [
                _vm.isReceiptAvailable
                  ? _c(
                      "sw-button",
                      {
                        staticClass: "mr-2",
                        attrs: {
                          href: _vm.getReceiptUrl,
                          "tag-name": "a",
                          variant: "primary",
                          outline: "",
                          size: "lg",
                        },
                      },
                      [
                        _c("download-icon", { staticClass: "h-5 mr-2 -ml-1" }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("expenses.download_receipt")) +
                            "\n        "
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "hidden md:block" },
                  [
                    _c(
                      "sw-button",
                      {
                        attrs: {
                          loading: _vm.isLoading,
                          disabled: _vm.isLoading,
                          variant: "primary",
                          type: "submit",
                          size: "lg",
                        },
                      },
                      [
                        !_vm.isLoading
                          ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                          : _vm._e(),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.isEdit
                                ? _vm.$t("expenses.update_expense")
                                : _vm.$t("expenses.save_expense")
                            ) +
                            "\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          2
        ),
        _vm._v(" "),
        _vm.isRequestOnGoing
          ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
          : _c("sw-card", [
              _c(
                "div",
                { staticClass: "grid gap-6 grid-col-1 md:grid-cols-2" },
                [
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("expenses.category"),
                        error: _vm.categoryError,
                        required: "",
                      },
                    },
                    [
                      _c(
                        "sw-select",
                        {
                          ref: "baseSelect",
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.categories,
                            invalid: _vm.$v.category.$error,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t(
                              "expenses.categories.select_a_category"
                            ),
                            label: "name",
                            "track-by": "id",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.category.$touch()
                            },
                          },
                          model: {
                            value: _vm.category,
                            callback: function ($$v) {
                              _vm.category = $$v
                            },
                            expression: "category",
                          },
                        },
                        [
                          _c(
                            "sw-button",
                            {
                              staticClass:
                                "flex items-center justify-center w-full px-4 py-3 bg-gray-200 border-none outline-none",
                              attrs: {
                                slot: "afterList",
                                type: "button",
                                variant: "gray-light",
                              },
                              on: { click: _vm.openCategoryModal },
                              slot: "afterList",
                            },
                            [
                              _c("shopping-cart-icon", {
                                staticClass: "h-5 text-center text-primary-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "ml-2 text-xs leading-none text-primary-400",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.expense_category.add_new_category"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("expenses.expense_date"),
                        error: _vm.dateError,
                        required: "",
                      },
                    },
                    [
                      _c("base-date-picker", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.expense_date.$error,
                          "calendar-button": true,
                          "calendar-button-icon": "calendar",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.expense_date.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.expense_date,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "expense_date", $$v)
                          },
                          expression: "formData.expense_date",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("expenses.amount"),
                        error: _vm.amountError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-money", {
                        staticClass:
                          "focus:border focus:border-solid focus:border-primary-500",
                        attrs: {
                          currency: _vm.defaultCurrencyForInput,
                          invalid: _vm.$v.formData.amount.$error,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.amount.$touch()
                          },
                        },
                        model: {
                          value: _vm.amount,
                          callback: function ($$v) {
                            _vm.amount = $$v
                          },
                          expression: "amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("expenses.customer") } },
                    [
                      _c("sw-select", {
                        ref: "baseSelect",
                        staticClass: "mt-1",
                        attrs: {
                          options: _vm.customers,
                          searchable: true,
                          "show-labels": false,
                          placeholder: _vm.$t("customers.select_a_customer"),
                          label: "name",
                          "track-by": "id",
                        },
                        model: {
                          value: _vm.customer,
                          callback: function ($$v) {
                            _vm.customer = $$v
                          },
                          expression: "customer",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      attrs: {
                        label: _vm.$t("expenses.note"),
                        error: _vm.notesError,
                      },
                    },
                    [
                      _c("sw-textarea", {
                        attrs: { rows: "4" },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.notes.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.notes,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "notes", $$v)
                          },
                          expression: "formData.notes",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    { attrs: { label: _vm.$t("expenses.receipt") } },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex items-center justify-center h-24 p-6 bg-transparent border-2 border-gray-200 border-dashed rounded-md image-upload-box",
                          attrs: { id: "receipt-box" },
                        },
                        [
                          _vm.previewReceipt
                            ? _c("img", {
                                staticClass:
                                  "absolute opacity-100 preview-logo",
                                staticStyle: {
                                  "max-height": "80%",
                                  animation: "fadeIn 2s ease",
                                },
                                attrs: { src: _vm.previewReceipt },
                              })
                            : _c(
                                "div",
                                { staticClass: "flex flex-col items-center" },
                                [
                                  _c("cloud-upload-icon", {
                                    staticClass:
                                      "h-5 mb-2 text-xl leading-6 text-gray-400",
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-xs leading-4 text-center text-gray-400",
                                    },
                                    [
                                      _vm._v(
                                        "\n                Drag a file here or\n                "
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "cursor-pointer text-primary-500",
                                          attrs: { id: "pick-avatar" },
                                        },
                                        [_vm._v("browse")]
                                      ),
                                      _vm._v(
                                        "\n                to choose a file\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("sw-avatar", {
                        attrs: {
                          "preview-avatar": _vm.previewReceipt,
                          "enable-cropper": false,
                          trigger: "#receipt-box",
                        },
                        on: { changed: _vm.onChange },
                        scopedSlots: _vm._u([
                          {
                            key: "icon",
                            fn: function () {
                              return [
                                _c("cloud-upload-icon", {
                                  staticClass:
                                    "h-5 mb-2 text-xl leading-6 text-gray-400",
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.customFields.length > 0
                ? _c("div", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "grid gap-6 mt-6 grid-col-1 md:grid-cols-2",
                      },
                      _vm._l(_vm.customFields, function (field, index) {
                        return _c(
                          "sw-input-group",
                          {
                            key: index,
                            attrs: {
                              label: field.label,
                              required: field.is_required ? true : false,
                            },
                          },
                          [
                            _c(field.type + "Field", {
                              tag: "component",
                              attrs: {
                                type: field.type.label,
                                field: field,
                                "is-edit": _vm.isEdit,
                                "invalid-fields": _vm.invalidFields,
                              },
                              on: { update: _vm.setCustomFieldValue },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "block mt-2 md:hidden" },
                [
                  _c(
                    "sw-button",
                    {
                      staticClass: "flex w-full",
                      attrs: {
                        disabled: _vm.isLoading,
                        loading: _vm.isLoading,
                        tabindex: 6,
                        variant: "primary",
                        type: "submit",
                        size: "lg",
                      },
                    },
                    [
                      !_vm.isLoading
                        ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                        : _vm._e(),
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            _vm.isEdit
                              ? _vm.$t("expenses.update_expense")
                              : _vm.$t("expenses.save_expense")
                          ) +
                          "\n        "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }