var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-transition", { attrs: { type: "fade" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isSidebarOpen,
              expression: "isSidebarOpen",
            },
          ],
          staticClass: "fixed top-0 left-0 z-20 w-full h-full",
          staticStyle: { background: "rgba(48, 75, 88, 0.5)" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.toggleSidebar.apply(null, arguments)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "hidden w-56 h-screen pb-32 overflow-y-auto bg-white border-r border-gray-200 border-solid xl:w-64 sw-scroll md:block",
        },
        [
          _vm._l(_vm.menuItems, function (menuItems, groupIndex) {
            return _c(
              "sw-list",
              { key: groupIndex, attrs: { variant: "sidebar" } },
              _vm._l(menuItems, function (item, index) {
                return _c(
                  "sw-list-item",
                  {
                    key: index,
                    attrs: {
                      title: _vm.$t(item.title),
                      active: _vm.hasActiveUrl(item.route),
                      to: item.route,
                      "tag-name": "router-link",
                    },
                  },
                  [
                    _c(item.icon, {
                      tag: "component",
                      staticClass: "h-5",
                      attrs: { slot: "icon" },
                      slot: "icon",
                    }),
                  ],
                  1
                )
              }),
              1
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "p-0 m-0 list-none mt-10 first:mt-6" }, [
            _c(
              "a",
              {
                staticClass:
                  "cursor-pointer px-0 py-3 flex items-center block border-l-3 border-solid border-gray-100",
                attrs: { href: "/sync-with-crm", target: "_blank" },
              },
              [
                _c(
                  "span",
                  {
                    staticClass:
                      "inline-block overflow-visible h-5 w-5 ml-6 text-sm text-gray-500 mr-5",
                  },
                  [
                    _c("receipt-refund-icon", {
                      tag: "component",
                      staticClass: "h-5",
                      attrs: { slot: "icon" },
                      slot: "icon",
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-sm not-italic font-medium leading-5 text-black",
                  },
                  [_vm._v("Sync To Crm")]
                ),
              ]
            ),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-class": "-translate-x-full",
            "enter-active-class":
              "transition duration-300 ease-in-out transform",
            "enter-to-class": "translate-x-0",
            "leave-active-class":
              "transition duration-300 ease-in-out transform",
            "leave-class": "translate-x-0",
            "leave-to-class": "-translate-x-full",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isSidebarOpen,
                  expression: "isSidebarOpen",
                },
              ],
              staticClass:
                "fixed top-0 z-30 w-64 h-screen pt-16 pb-32 overflow-y-auto bg-white border-r border-gray-200 border-solid sw-scroll md:hidden",
            },
            _vm._l(_vm.menuItems, function (menuItems, groupIndex) {
              return _c(
                "sw-list",
                { key: groupIndex, attrs: { variant: "sidebar" } },
                _vm._l(menuItems, function (item, index) {
                  return _c(
                    "sw-list-item",
                    {
                      key: index,
                      attrs: {
                        title: _vm.$t(item.title),
                        active: _vm.hasActiveUrl(item.route),
                        to: item.route,
                        "tag-name": "router-link",
                      },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.toggleSidebar.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c(item.icon, {
                        tag: "component",
                        staticClass: "h-5",
                        attrs: { slot: "icon" },
                        slot: "icon",
                      }),
                    ],
                    1
                  )
                }),
                1
              )
            }),
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }