var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass:
        "box-border bg-white border border-gray-200 border-solid rounded-b",
      class: _vm.item.hide_on_invoice ? "opacity-50" : "",
    },
    [
      _c(
        "td",
        { staticClass: "p-0 text-left align-top", attrs: { colspan: "5" } },
        [
          _c("table", { staticClass: "w-full" }, [
            _c("colgroup", [
              _c("col", { staticStyle: { width: "40%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "10%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "10%" } }),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
              _vm._v(" "),
              _vm.discountPerItem === "YES"
                ? _c("col", { staticStyle: { width: "15%" } })
                : _vm._e(),
              _vm._v(" "),
              _c("col", { staticStyle: { width: "15%" } }),
            ]),
            _vm._v(" "),
            _c("tbody", [
              _c("tr", [
                _c("td", { staticClass: "px-5 pt-4 text-left align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex justify-start" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                        },
                        [_c("drag-icon")],
                        1
                      ),
                      _vm._v(" "),
                      _c("item-select", {
                        ref: "itemSelect",
                        attrs: {
                          invalid: _vm.$v.item.name.$error,
                          "invalid-description": _vm.$v.item.description.$error,
                          item: _vm.item,
                          "tax-per-item": _vm.taxPerItem,
                          taxes: _vm.item.taxes,
                        },
                        on: {
                          search: _vm.searchVal,
                          select: _vm.onSelectItem,
                          deselect: _vm.deselectItem,
                          onDesriptionInput: function ($event) {
                            return _vm.$v.item.description.$touch()
                          },
                          onSelectItem: function ($event) {
                            _vm.isSelected = true
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-5 pt-4 text-left align-top" },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.item.quantity.$error,
                        type: "text",
                        small: "",
                      },
                      on: {
                        keyup: _vm.updateItem,
                        input: function ($event) {
                          return _vm.$v.item.quantity.$touch()
                        },
                      },
                      model: {
                        value: _vm.item.unit_name,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "unit_name", $$v)
                        },
                        expression: "item.unit_name",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.item.quantity.$error
                      ? _c("div", [
                          !_vm.$v.item.quantity.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t("validation.quantity_maxlength")
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "td",
                  { staticClass: "px-5 pt-4 text-left align-top" },
                  [
                    _c("sw-input", {
                      attrs: {
                        invalid: _vm.$v.item.quantity.$error,
                        type: "text",
                        small: "",
                      },
                      on: {
                        keyup: _vm.updateItem,
                        input: function ($event) {
                          return _vm.$v.item.quantity.$touch()
                        },
                      },
                      model: {
                        value: _vm.item.quantity,
                        callback: function ($$v) {
                          _vm.$set(_vm.item, "quantity", $$v)
                        },
                        expression: "item.quantity",
                      },
                    }),
                    _vm._v(" "),
                    _vm.$v.item.quantity.$error
                      ? _c("div", [
                          !_vm.$v.item.quantity.maxLength
                            ? _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm.$t("validation.quantity_maxlength")
                                    ) +
                                    "\n                            "
                                ),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 pt-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex flex-col" }, [
                    _c(
                      "div",
                      { staticClass: "flex-auto flex-fill bd-highlight" },
                      [
                        _c("div", { staticClass: "relative w-full" }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "relative flex items-center w-full border border-solid rounded-md bg-white border-gray-300 focus:border-transparent",
                          },
                          [
                            _c("input", {
                              staticClass:
                                "not-italic font-normal leading-tight text-left outline-none rounded-md input-field box-border-2 placeholder-gray-400 text-black w-full h-10 px-3 py-2 text-sm",
                              attrs: { type: "text" },
                              domProps: { value: _vm.getPrice() },
                              on: {
                                change: function ($event) {
                                  return _vm.setPrice($event)
                                },
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.$v.item.price.$error
                          ? _c("div", [
                              !_vm.$v.item.price.maxLength
                                ? _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.$t("validation.price_maxlength")
                                        ) +
                                        "\n                                    "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.discountPerItem === "YES"
                  ? _c("td", { staticClass: "px-5 pt-4 text-left align-top" }, [
                      _c("div", { staticClass: "flex flex-col" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex flex-auto",
                            attrs: { role: "group" },
                          },
                          [
                            _c("sw-input", {
                              staticClass:
                                "border-r-0 rounded-tr-none rounded-br-none",
                              attrs: {
                                invalid: _vm.$v.item.discount_val.$error,
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.item.discount_val.$touch()
                                },
                              },
                              model: {
                                value: _vm.discount,
                                callback: function ($$v) {
                                  _vm.discount = $$v
                                },
                                expression: "discount",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown",
                              [
                                _c(
                                  "sw-button",
                                  {
                                    staticStyle: { height: "43px" },
                                    attrs: {
                                      slot: "activator",
                                      type: "button",
                                      "data-toggle": "dropdown",
                                      size: "discount",
                                      "aria-haspopup": "true",
                                      "aria-expanded": "false",
                                      variant: "white",
                                    },
                                    slot: "activator",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "flex items-center" },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              _vm.item.discount_type == "fixed"
                                                ? _vm.currency.symbol
                                                : "%"
                                            ) +
                                            "\n                                            "
                                        ),
                                        _c("chevron-down-icon", {
                                          staticClass: "h-5",
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  { on: { click: _vm.selectFixed } },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("general.fixed")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "sw-dropdown-item",
                                  { on: { click: _vm.selectPercentage } },
                                  [
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(_vm.$t("general.percentage")) +
                                        "\n                                "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 pt-4 text-right align-top" }, [
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-end text-sm" },
                    [
                      _c("span", [
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(_vm.total, _vm.currency)
                            ),
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex items-center justify-center w-6 h-10 mx-2 cursor-pointer",
                        },
                        [
                          _vm.showRemoveItemIcon
                            ? _c("trash-icon", {
                                staticClass: "h-5 text-gray-700",
                                on: { click: _vm.removeItem },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("td", { staticClass: "px-5 pb-4 text-left align-top" }, [
                  _c("div", { staticClass: "flex justify-start" }, [
                    _c("div", {
                      staticClass:
                        "flex items-center justify-center w-12 h-5 mt-2 text-gray-400 cursor-move handle",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "w-full pt-1 text-xs text-light" },
                      [
                        _c("sw-textarea", {
                          directives: [
                            { name: "autoresize", rawName: "v-autoresize" },
                          ],
                          staticClass:
                            "w-full text-xs text-gray-600 border-none resize-none",
                          attrs: {
                            "invalid-description": _vm.invalidDescription,
                            placeholder: _vm.$t(
                              "invoices.item.type_item_description"
                            ),
                            type: "text",
                            variant: "inv-desc",
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$emit("onDesriptionInput")
                            },
                          },
                          model: {
                            value: _vm.item.description,
                            callback: function ($$v) {
                              _vm.$set(_vm.item, "description", $$v)
                            },
                            expression: "item.description",
                          },
                        }),
                        _vm._v(" "),
                        _vm.invalidDescription
                          ? _c("div", [
                              _c(
                                "span",
                                { staticClass: "text-xs text-danger" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$tc(
                                        "validation.description_maxlength"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "px-5 py-4 text-right align-top",
                    attrs: { colspan: "2" },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "relative flex items-center w-full border border-solid rounded-md bg-white border-gray-300 focus:border-transparent",
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.internal_note,
                                expression: "item.internal_note",
                              },
                            ],
                            staticClass:
                              "form-control not-italic font-normal px-3 leading-tight text-left outline-none rounded-md input-field box-border-2 placeholder-gray-400 text-black w-full h-10 px-3 py-2 text-sm",
                            attrs: {
                              type: "text",
                              placeholder: "Internal note",
                            },
                            domProps: { value: _vm.item.internal_note },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.item,
                                  "internal_note",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "td",
                  {
                    staticClass: "px-5 py-4 text-left align-top",
                    attrs: { colspan: "1" },
                  },
                  [
                    _c("div", [
                      _c("div", {}, [
                        _c("div", { staticClass: "form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.item.hide_on_invoice,
                                expression: "item.hide_on_invoice",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: {
                              type: "checkbox",
                              value: "",
                              id: "hide_on_invoice",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.item.hide_on_invoice)
                                ? _vm._i(_vm.item.hide_on_invoice, "") > -1
                                : _vm.item.hide_on_invoice,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.item.hide_on_invoice,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.item,
                                        "hide_on_invoice",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.item,
                                        "hide_on_invoice",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.item, "hide_on_invoice", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label small",
                              attrs: { for: "hide_on_invoice" },
                            },
                            [
                              _vm._v(
                                "\n                                    Hide\n                                "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.discountPerItem === "YES"
                  ? _c("td", { staticClass: "px-5 py-4 text-left align-top" })
                  : _vm._e(),
                _vm._v(" "),
                _c("td", { staticClass: "px-5 py-4 text-right align-top" }),
              ]),
              _vm._v(" "),
              _vm.taxPerItem === "YES"
                ? _c("tr", { staticClass: "tax-tr" }, [
                    _c("td", { staticClass: "px-5 py-4 text-left align-top" }),
                    _vm._v(" "),
                    _c("td", {
                      staticClass: "px-5 py-4 text-left align-top",
                      attrs: { colspan: "4" },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }