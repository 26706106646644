var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-card",
    { attrs: { variant: "setting-card" } },
    [
      _c(
        "div",
        {
          staticClass: "flex flex-wrap justify-between lg:flex-nowrap",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("div", [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.menu_title.custom_fields")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$t("settings.custom_fields.section_description")
                    ) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mt-4 lg:mt-0 lg:ml-2" },
            [
              _c(
                "sw-button",
                {
                  attrs: { variant: "primary-outline", size: "lg" },
                  on: { click: _vm.addCustomField },
                },
                [
                  _c("plus-icon", { staticClass: "w-6 h-6 mr-1 -ml-2" }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("settings.custom_fields.add_custom_field")
                      ) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "sw-table-component",
        {
          ref: "table",
          attrs: { "show-filter": false, data: _vm.fetchData, variant: "gray" },
        },
        [
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.custom_fields.name"),
              show: "name",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.custom_fields.label"),
              show: "label",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.custom_fields.model"),
              show: "model_type",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              label: _vm.$t("settings.custom_fields.type"),
              show: "type.label",
            },
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: true,
              filterable: true,
              label: _vm.$t("settings.custom_fields.required"),
              show: "is_required",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.custom_fields.required"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-badge",
                      {
                        attrs: {
                          "bg-color": _vm.$utils.getBadgeStatusColor(
                            row.is_required ? "YES" : "NO"
                          ).bgColor,
                          color: _vm.$utils.getBadgeStatusColor(
                            row.is_required ? "YES" : "NO"
                          ).color,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              row.is_required
                                ? _vm.$t("settings.custom_fields.yes")
                                : _vm
                                    .$t("settings.custom_fields.no")
                                    .replace("_", " ")
                            ) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("sw-table-column", {
            attrs: {
              sortable: false,
              filterable: false,
              "cell-class": "action-dropdown",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (row) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("settings.tax_types.action"))),
                    ]),
                    _vm._v(" "),
                    _c(
                      "sw-dropdown",
                      [
                        _c("dot-icon", {
                          attrs: { slot: "activator" },
                          slot: "activator",
                        }),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.editCustomField(row.id)
                              },
                            },
                          },
                          [
                            _c("pencil-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.edit")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown-item",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.removeCustomField(row.id)
                              },
                            },
                          },
                          [
                            _c("trash-icon", {
                              staticClass: "h-5 mr-3 text-gray-600",
                            }),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("general.delete")) +
                                "\n          "
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }