var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      staticClass: "relative",
      attrs: { action: "" },
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.updatePreferencesData.apply(null, arguments)
        },
      },
    },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.menu_title.preferences")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.preferences.general_settings")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
            [
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.preferences.currency"),
                    error: _vm.currencyError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.currency.$error },
                    attrs: {
                      options: _vm.currencies,
                      "custom-label": _vm.currencyNameWithCode,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.currencies.select_currency"
                      ),
                      label: "name",
                      "track-by": "id",
                    },
                    model: {
                      value: _vm.formData.currency,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "currency", $$v)
                      },
                      expression: "formData.currency",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.preferences.default_language"),
                    error: _vm.languageError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.language.$error },
                    attrs: {
                      options: _vm.languages,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_language"
                      ),
                      label: "name",
                      "track-by": "code",
                    },
                    model: {
                      value: _vm.formData.language,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "language", $$v)
                      },
                      expression: "formData.language",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.preferences.time_zone"),
                    error: _vm.timeZoneError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.timeZone.$error },
                    attrs: {
                      options: _vm.timeZones,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_time_zone"
                      ),
                      label: "key",
                      "track-by": "key",
                    },
                    model: {
                      value: _vm.formData.timeZone,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "timeZone", $$v)
                      },
                      expression: "formData.timeZone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  attrs: {
                    label: _vm.$tc("settings.preferences.date_format"),
                    error: _vm.dateFormatError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    staticClass: "mt-2",
                    class: { error: _vm.$v.formData.dateFormat.$error },
                    attrs: {
                      options: _vm.dateFormats,
                      searchable: true,
                      "show-labels": false,
                      "allow-empty": false,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_date_format"
                      ),
                      label: "display_date",
                    },
                    model: {
                      value: _vm.formData.dateFormat,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "dateFormat", $$v)
                      },
                      expression: "formData.dateFormat",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "mb-2",
                  attrs: {
                    label: _vm.$tc("settings.preferences.fiscal_year"),
                    error: _vm.fiscalYearError,
                    required: "",
                  },
                },
                [
                  _c("sw-select", {
                    class: { error: _vm.$v.formData.fiscalYear.$error },
                    attrs: {
                      options: _vm.fiscalYears,
                      "show-labels": false,
                      "allow-empty": false,
                      searchable: true,
                      placeholder: _vm.$tc(
                        "settings.preferences.select_financial_year"
                      ),
                      label: "key",
                      "track-by": "value",
                    },
                    model: {
                      value: _vm.formData.fiscalYear,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "fiscalYear", $$v)
                      },
                      expression: "formData.fiscalYear",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-6",
              attrs: {
                disabled: _vm.isLoading,
                loading: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$tc("settings.company_info.save")) +
                  "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("sw-divider", { staticClass: "mt-6 mb-8" }),
          _vm._v(" "),
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "relative w-12" },
              [
                _c("sw-switch", {
                  staticClass: "absolute",
                  staticStyle: { top: "-18px" },
                  on: { change: _vm.setDiscount },
                  model: {
                    value: _vm.discount_per_item,
                    callback: function ($$v) {
                      _vm.discount_per_item = $$v
                    },
                    expression: "discount_per_item",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "ml-15" }, [
              _c(
                "p",
                { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("settings.preferences.discount_per_item")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
                  staticStyle: { "max-width": "480px" },
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t(
                          "settings.preferences.discount_setting_description"
                        )
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }