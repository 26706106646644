var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "form",
        {
          staticClass: "mt-6",
          attrs: { action: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.updateInvoiceSetting.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.customization.invoices.invoice_prefix"),
                error: _vm.invoicePrefixError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "30%" },
                attrs: { invalid: _vm.$v.invoices.invoice_prefix.$error },
                on: {
                  input: function ($event) {
                    return _vm.$v.invoices.invoice_prefix.$touch()
                  },
                  keyup: function ($event) {
                    return _vm.changeToUppercase("INVOICES")
                  },
                },
                model: {
                  value: _vm.invoices.invoice_prefix,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_prefix", $$v)
                  },
                  expression: "invoices.invoice_prefix",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.invoice_number_length"
                ),
                error: _vm.invoicenumberLengthError,
              },
            },
            [
              _c("sw-input", {
                staticStyle: { "max-width": "60px" },
                attrs: {
                  invalid: _vm.$v.invoices.invoice_number_length.$error,
                  type: "number",
                },
                model: {
                  value: _vm.invoices.invoice_number_length,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_number_length", $$v)
                  },
                  expression: "invoices.invoice_number_length",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.default_invoice_email_body"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.InvoiceMailFields },
                model: {
                  value: _vm.invoices.invoice_mail_body,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "invoice_mail_body", $$v)
                  },
                  expression: "invoices.invoice_mail_body",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.company_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.companyFields },
                model: {
                  value: _vm.invoices.company_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "company_address_format", $$v)
                  },
                  expression: "invoices.company_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.shipping_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.shippingFields },
                model: {
                  value: _vm.invoices.shipping_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "shipping_address_format", $$v)
                  },
                  expression: "invoices.shipping_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              staticClass: "mt-6 mb-4",
              attrs: {
                label: _vm.$t(
                  "settings.customization.invoices.billing_address_format"
                ),
              },
            },
            [
              _c("base-custom-input", {
                staticClass: "mt-2",
                attrs: { fields: _vm.billingFields },
                model: {
                  value: _vm.invoices.billing_address_format,
                  callback: function ($$v) {
                    _vm.$set(_vm.invoices, "billing_address_format", $$v)
                  },
                  expression: "invoices.billing_address_format",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              staticClass: "mt-4",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              !_vm.isLoading
                ? _c("save-icon", { staticClass: "mr-2" })
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("settings.customization.save")) +
                  "\n    "
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("sw-divider", { staticClass: "mt-6 mb-8" }),
      _vm._v(" "),
      _c("div", { staticClass: "flex mt-3 mb-4" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setInvoiceSetting },
              model: {
                value: _vm.invoiceAutogenerate,
                callback: function ($$v) {
                  _vm.invoiceAutogenerate = $$v
                },
                expression: "invoiceAutogenerate",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.autogenerate_invoice_number"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.invoice_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "flex mb-2" }, [
        _c(
          "div",
          { staticClass: "relative w-12" },
          [
            _c("sw-switch", {
              staticClass: "absolute",
              staticStyle: { top: "-20px" },
              on: { change: _vm.setInvoiceSetting },
              model: {
                value: _vm.invoiceAsAttachment,
                callback: function ($$v) {
                  _vm.invoiceAsAttachment = $$v
                },
                expression: "invoiceAsAttachment",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "ml-4" }, [
          _c(
            "p",
            { staticClass: "p-0 mb-1 text-base leading-snug text-black" },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.invoice_email_attachment"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "p",
            {
              staticClass: "p-0 m-0 text-xs leading-tight text-gray-500",
              staticStyle: { "max-width": "480px" },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.customization.invoices.invoice_email_attachment_setting_description"
                    )
                  ) +
                  "\n      "
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }