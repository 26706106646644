var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    [
      _c(
        "sw-page-header",
        { staticClass: "mb-3", attrs: { title: _vm.pageTitle } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$t("general.home"),
                  to: "/admin/dashboard",
                },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$tc("items.item", 2), to: "/admin/items" },
              }),
              _vm._v(" "),
              _vm.$route.name === "items.edit"
                ? _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("items.edit_item"),
                      to: "#",
                      active: "",
                    },
                  })
                : _c("sw-breadcrumb-item", {
                    attrs: {
                      title: _vm.$t("items.new_item"),
                      to: "#",
                      active: "",
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "grid grid-cols-12" }, [
        _c("div", { staticClass: "col-span-12 md:col-span-6" }, [
          _c(
            "form",
            {
              attrs: { action: "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submitItem.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "sw-card",
                [
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("items.name"),
                        error: _vm.nameError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-input", {
                        staticClass: "mt-2",
                        attrs: {
                          invalid: _vm.$v.formData.name.$error,
                          focus: "",
                          type: "text",
                          name: "name",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.name.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.name,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formData,
                              "name",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formData.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("items.price"),
                        error: _vm.priceError,
                        required: "",
                      },
                    },
                    [
                      _c("sw-money", {
                        staticClass:
                          "relative w-full focus:border focus:border-solid focus:border-primary-500",
                        attrs: {
                          invalid: _vm.$v.formData.price.$error,
                          currency: _vm.defaultCurrencyForInput,
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.price.$touch()
                          },
                        },
                        model: {
                          value: _vm.price,
                          callback: function ($$v) {
                            _vm.price =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "price",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: { label: _vm.$t("items.unit") },
                    },
                    [
                      _c(
                        "sw-select",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            options: _vm.itemUnits,
                            searchable: true,
                            "show-labels": false,
                            placeholder: _vm.$t("items.select_a_unit"),
                            label: "name",
                          },
                          model: {
                            value: _vm.formData.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.formData, "unit", $$v)
                            },
                            expression: "formData.unit",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex items-center justify-center w-full px-6 py-3 text-base bg-gray-200 cursor-pointer text-primary-400",
                              attrs: { slot: "afterList" },
                              on: { click: _vm.addItemUnit },
                              slot: "afterList",
                            },
                            [
                              _c("shopping-cart-icon", {
                                staticClass:
                                  "h-5 mr-2 -ml-2 text-center text-primary-400",
                              }),
                              _vm._v(" "),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "ml-2 text-sm leading-none text-primary-400",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "settings.customization.items.add_item_unit"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isTaxPerItem
                    ? _c(
                        "sw-input-group",
                        {
                          staticClass: "mb-4",
                          attrs: { label: _vm.$t("items.taxes") },
                        },
                        [
                          _c("sw-select", {
                            staticClass: "mt-2",
                            attrs: {
                              options: _vm.getTaxTypes,
                              searchable: true,
                              "show-labels": false,
                              "allow-empty": true,
                              multiple: true,
                              "track-by": "tax_type_id",
                              label: "tax_name",
                            },
                            model: {
                              value: _vm.formData.taxes,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "taxes", $$v)
                              },
                              expression: "formData.taxes",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "sw-input-group",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        label: _vm.$t("items.description"),
                        error: _vm.descriptionError,
                      },
                    },
                    [
                      _c("sw-textarea", {
                        attrs: { rows: "2", name: "description" },
                        on: {
                          input: function ($event) {
                            return _vm.$v.formData.description.$touch()
                          },
                        },
                        model: {
                          value: _vm.formData.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "description", $$v)
                          },
                          expression: "formData.description",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "flex justify-center w-full md:w-auto",
                          attrs: {
                            loading: _vm.isLoading,
                            variant: "primary",
                            size: "lg",
                          },
                        },
                        [
                          !_vm.isLoading
                            ? _c("save-icon", { staticClass: "mr-2 -ml-1" })
                            : _vm._e(),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.isEdit
                                  ? _vm.$t("items.update_item")
                                  : _vm.$t("items.save_item")
                              ) +
                              "\n            "
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }