var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pt-6 mt-5 border-t-2 border-solid lg:pt-8 md:pt-4",
      staticStyle: { "border-top-color": "#f9fbff" },
    },
    [
      _c("div", { staticClass: "col-span-12" }, [
        _c("p", { staticClass: "text-gray-500 uppercase sw-section-title" }, [
          _vm._v(
            "\n      " + _vm._s(_vm.$t("customers.basic_info")) + "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
          },
          [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.display_name")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.name
                          ? _vm.selectedViewCustomer.customer.name
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.primary_contact_name")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.contact_name
                          ? _vm.selectedViewCustomer.customer.contact_name
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.email")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.email
                          ? _vm.selectedViewCustomer.customer.email
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
          },
          [
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("wizard.currency")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer.currency
                          ? `${_vm.selectedViewCustomer.customer.currency.code} (${_vm.selectedViewCustomer.customer.currency.symbol})`
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.phone_number")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.phone
                          ? _vm.selectedViewCustomer.customer.phone
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "p",
                {
                  staticClass:
                    "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.$t("customers.website")) +
                      "\n        "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "text-sm font-bold leading-5 text-black non-italic",
                },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.selectedViewCustomer.customer &&
                          _vm.selectedViewCustomer.customer.website
                          ? _vm.selectedViewCustomer.customer.website
                          : ""
                      ) +
                      "\n        "
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _vm.getFormattedShippingAddress.length ||
        _vm.getFormattedBillingAddress.length
          ? _c(
              "p",
              { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("customers.address")) + "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 md:grid-cols-2 sm:grid-cols-1 lg:grid-cols-2",
          },
          [
            _vm.getFormattedBillingAddress.length
              ? _c("div", { staticClass: "mt-5" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.billing_address")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(_vm.getFormattedBillingAddress),
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.getFormattedShippingAddress.length
              ? _c("div", { staticClass: "mt-5" }, [
                  _c(
                    "p",
                    {
                      staticClass:
                        "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("customers.shipping_address")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("p", {
                    staticClass:
                      "text-sm font-bold leading-5 text-black non-italic",
                    domProps: {
                      innerHTML: _vm._s(_vm.getFormattedShippingAddress),
                    },
                  }),
                ])
              : _vm._e(),
          ]
        ),
        _vm._v(" "),
        _vm.getCustomField.length > 0
          ? _c(
              "p",
              { staticClass: "mt-8 text-gray-500 uppercase sw-section-title" },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.custom_fields.title")) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 gap-4 mt-5 lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1",
          },
          _vm._l(_vm.getCustomField, function (field, index) {
            return _c(
              "div",
              {
                key: index,
                attrs: { required: field.is_required ? true : false },
              },
              [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-1 text-sm font-normal leading-5 non-italic text-primary-800",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(field.custom_field.label) +
                        "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                field.type === "Switch"
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        field.defaultAnswer === 1
                          ? _c("span", [_vm._v(" Yes ")])
                          : _c("span", [_vm._v(" No ")]),
                      ]
                    )
                  : _c(
                      "p",
                      {
                        staticClass:
                          "text-sm font-bold leading-5 text-black non-italic",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(field.defaultAnswer) +
                            "\n        "
                        ),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }