var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("sw-input", {
        attrs: {
          invalid: _vm.$v.inputValue.$error || _vm.isInvalid,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
          type: "url",
        },
        on: { input: _vm.handleInput, change: _vm.handleChange },
        model: {
          value: _vm.inputValue,
          callback: function ($$v) {
            _vm.inputValue = $$v
          },
          expression: "inputValue",
        },
      }),
      _vm._v(" "),
      _vm.$v.inputValue.$error || _vm.isInvalid
        ? _c("div", [
            !_vm.$v.inputValue.url
              ? _c("span", { staticClass: "text-sm text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("validation.invalid_url")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isInvalid
              ? _c("span", { staticClass: "text-sm text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("validation.required")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }