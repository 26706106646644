var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "relative" },
    [
      _vm.isRequestOnGoing
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "sw-card",
        { attrs: { variant: "setting-card" } },
        [
          _c("template", { slot: "header" }, [
            _c("h6", { staticClass: "sw-section-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("settings.mail.mail_config")) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              {
                staticClass: "mt-2 text-sm leading-snug text-gray-500",
                staticStyle: { "max-width": "680px" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.mail.mail_config_desc")) +
                    "\n      "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.mailConfigData
            ? _c(
                "div",
                [
                  _c(
                    _vm.mail_driver,
                    {
                      tag: "component",
                      attrs: {
                        "config-data": _vm.mailConfigData,
                        loading: _vm.isLoading,
                        "mail-drivers": _vm.mail_drivers,
                      },
                      on: {
                        "on-change-driver": (val) =>
                          (_vm.mail_driver = _vm.mailConfigData.mail_driver =
                            val),
                        "submit-data": _vm.saveEmailConfig,
                      },
                    },
                    [
                      _c(
                        "sw-button",
                        {
                          staticClass: "ml-2",
                          attrs: { variant: "primary-outline", type: "button" },
                          on: { click: _vm.openMailTestModal },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("general.test_mail_conf")) +
                              "\n        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }