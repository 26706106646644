var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sw-modal",
        {
          ref: "baseModal",
          attrs: { variant: _vm.variant },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "absolute flex content-center justify-center w-5 cursor-pointer",
                      staticStyle: { top: "20px", right: "15px" },
                      on: { click: _vm.closeModal },
                    },
                    [_c("x-icon")],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.modalTitle))]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [_vm._v(" "), _c(_vm.component, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }