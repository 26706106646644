var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "template-modal" }, [
    _c("div", { staticClass: "px-8 py-8 sm:p-6" }, [
      _c(
        "div",
        { staticClass: "grid grid-cols-3 gap-2 p-1 overflow-x-auto sw-scroll" },
        _vm._l(_vm.modalData, function (template, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass:
                "relative flex flex-col m-2 border border-gray-200 border-solid cursor-pointer hover:border-primary-300",
              class: {
                "border border-solid border-primary-500":
                  _vm.selectedTemplate === template.id,
              },
              on: {
                click: function ($event) {
                  _vm.selectedTemplate = template.id
                },
              },
            },
            [
              _c("img", {
                attrs: { src: template.path, alt: "template-image" },
              }),
              _vm._v(" "),
              _vm.selectedTemplate === template.id
                ? _c("img", {
                    staticClass: "absolute z-10 w-5 h-5 text-primary-500",
                    staticStyle: { top: "-6px", right: "-5px" },
                    attrs: { src: "/assets/img/tick.png" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: [
                    "w-full p-1 bg-gray-200 text-sm text-center absolute bottom-0 left-0",
                    {
                      "text-primary-500 bg-primary-100":
                        _vm.selectedTemplate === template.id,
                      "text-gray-600": _vm.selectedTemplate != template.id,
                    },
                  ],
                },
                [_vm._v("\n          " + _vm._s(template.name) + "\n        ")]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass:
          "z-0 flex justify-end p-4 border-t border-gray-200 border-solid",
      },
      [
        _c(
          "sw-button",
          {
            staticClass: "mr-3",
            attrs: { variant: "primary-outline" },
            on: { click: _vm.closeEstimateModal },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.cancel")) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "sw-button",
          {
            attrs: { variant: "primary" },
            on: {
              click: function ($event) {
                return _vm.chooseTemplate()
              },
            },
          },
          [_vm._v("\n      " + _vm._s(_vm.$t("general.choose")) + "\n    ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }