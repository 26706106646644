var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "118",
        height: "110",
        viewBox: "0 0 118 110",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0)" } }, [
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M58.6672 32.9999C42.1415 32.9999 32.973 28.5119 32.5898 28.3194L33.4093 26.6804C33.4992 26.7244 42.6127 31.1666 58.6672 31.1666C74.542 31.1666 83.8388 26.7208 83.9323 26.6768L84.7354 28.3231C84.3449 28.5156 74.9618 32.9999 58.6672 32.9999Z",
            fill: "#817AE3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M25.2438 39.0117L28.4191 40.8451C28.839 41.0871 29.1415 41.4831 29.2698 41.9597C29.3963 42.4346 29.3321 42.9296 29.0901 43.3494L14.4235 68.7521C14.099 69.3167 13.4866 69.6669 12.8248 69.6669C12.504 69.6669 12.1978 69.5844 11.9191 69.4231L8.74382 67.5897L7.82715 69.1774L11.0025 71.0107C11.5763 71.3426 12.2051 71.5002 12.8248 71.5002C14.0953 71.5002 15.3346 70.8421 16.0111 69.6687L30.6778 44.2661C31.6861 42.5189 31.083 40.2657 29.3358 39.2574L26.1605 37.4241L25.2438 39.0117Z",
            fill: "#817AE3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M91.1729 37.4241L87.9976 39.2574C86.2504 40.2657 85.6472 42.5189 86.6556 44.2661L101.322 69.6687C101.999 70.8421 103.238 71.5002 104.509 71.5002C105.128 71.5002 105.757 71.3426 106.331 71.0107L109.506 69.1774L108.59 67.5897L105.414 69.4231C105.139 69.5826 104.826 69.6669 104.509 69.6669C103.847 69.6669 103.234 69.3167 102.91 68.7521L88.2432 43.3494C88.0012 42.9296 87.9371 42.4346 88.0636 41.9597C88.1919 41.4831 88.4944 41.0871 88.9142 40.8451L92.0896 39.0117L91.1729 37.4241Z",
            fill: "#817AE3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M115.5 84.3333V87.6993C115.5 89.2797 114.424 90.6308 112.88 90.9883C112.013 91.19 111.049 91.4393 109.96 91.7198C102.573 93.6228 88.8268 97.1667 58.6667 97.1667C28.292 97.1667 14.6942 93.6338 7.38833 91.7345C6.29383 91.4503 5.324 91.1992 4.44767 90.9938C2.90767 90.6363 1.83333 89.2833 1.83333 87.7067V84.3333L0 82.5V87.7067C0 90.134 1.66833 92.2295 4.0315 92.7795C10.9322 94.3873 23.6812 99 58.6667 99C93.3478 99 106.372 94.3818 113.296 92.7758C115.661 92.2258 117.333 90.1285 117.333 87.6993V82.5",
            fill: "#817AE3",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M79.6139 20.1666L115.245 81.7354C115.841 82.7566 115.344 84.0656 114.214 84.4102C107.345 86.4966 89.3159 89.8333 58.6662 89.8333C27.9744 89.8333 9.97652 86.3371 3.12535 84.2526C1.99602 83.9079 1.49919 82.5989 2.09502 81.5778L37.7204 20.1666L36.6662 18.3333L0.503686 80.6666C-0.686148 82.7071 0.322186 85.3251 2.58085 86.0163C9.60985 88.1704 27.7104 91.6666 58.6662 91.6666C89.4625 91.6666 107.664 88.3189 114.742 86.1666C117.008 85.4772 118.022 82.8574 116.829 80.8133L80.6662 18.3333",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M110.814 92.4116L115.245 100.069C115.841 101.089 115.344 102.4 114.214 102.742C107.345 104.831 89.3159 108.167 58.6662 108.167C27.9744 108.167 9.97469 104.671 3.12535 102.585C1.99602 102.242 1.49919 100.931 2.09502 99.9117L6.41985 92.4556L4.75885 91.6672L0.503686 99.0006C-0.686148 101.041 0.322185 103.657 2.58085 104.35C9.60985 106.504 27.7104 110.001 58.6662 110.001C89.4625 110.001 107.664 106.653 114.742 104.501C117.007 103.811 118.022 101.191 116.829 99.1472L112.682 91.9789L110.814 92.4116Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M58.667 0C47.238 0 36.667 7.1335 36.667 18.3407V20.1667C36.667 20.1667 42.6052 23.8333 58.667 23.8333C74.6665 23.8333 80.667 20.1667 80.667 20.1667V18.3333C80.667 7.24167 70.767 0 58.667 0ZM58.667 1.83333C70.3527 1.83333 78.8337 8.7725 78.8337 18.3333V19.0172C76.6887 19.9302 70.5103 22 58.667 22C46.7705 22 40.6197 19.9283 38.5003 19.0227V18.3407C38.5003 12.3658 41.7692 8.55617 44.51 6.41117C48.2317 3.50167 53.3907 1.83333 58.667 1.83333Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M69.6667 53.1666C70.6768 53.1666 71.5 53.9898 71.5 54.9999V89.8333H73.3333V54.9999C73.3333 52.9741 71.6925 51.3333 69.6667 51.3333H47.6667C45.6408 51.3333 44 52.9741 44 54.9999V89.8333H45.8333V54.9999C45.8333 53.9898 46.6565 53.1666 47.6667 53.1666H69.6667Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M58.6667 56.8333C53.6048 56.8333 49.5 60.9381 49.5 65.9999C49.5 71.0618 53.6048 75.1666 58.6667 75.1666C63.7285 75.1666 67.8333 71.0618 67.8333 65.9999C67.8333 60.9381 63.7285 56.8333 58.6667 56.8333ZM58.6667 58.6666C62.711 58.6666 66 61.9556 66 65.9999C66 70.0443 62.711 73.3333 58.6667 73.3333C54.6223 73.3333 51.3333 70.0443 51.3333 65.9999C51.3333 61.9556 54.6223 58.6666 58.6667 58.6666Z",
            fill: "#55547A",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            "fill-rule": "evenodd",
            "clip-rule": "evenodd",
            d: "M63.2503 66C62.7443 66 62.3337 65.5893 62.3337 65.0833C62.3337 63.5672 61.0998 62.3333 59.5837 62.3333C59.0777 62.3333 58.667 61.9227 58.667 61.4167C58.667 60.9107 59.0777 60.5 59.5837 60.5C62.11 60.5 64.167 62.5552 64.167 65.0833C64.167 65.5893 63.7563 66 63.2503 66Z",
            fill: "#817AE3",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0" } }, [
          _c("rect", {
            attrs: { width: "117.333", height: "110", fill: "white" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }