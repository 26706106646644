var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("base-time-picker", {
        attrs: {
          "set-value": _vm.defaultValue,
          invalid: _vm.$v.time.$error,
          placeholder: _vm.placeholder,
          tabindex: _vm.tabindex,
          "hide-clear-button": "",
        },
        on: { input: _vm.onTimeSelect },
        model: {
          value: _vm.time,
          callback: function ($$v) {
            _vm.time = $$v
          },
          expression: "time",
        },
      }),
      _vm._v(" "),
      _vm.$v.time.$error
        ? _c("div", [
            !_vm.$v.time.required
              ? _c("span", { staticClass: "text-sm text-danger" }, [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("validation.required")) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }