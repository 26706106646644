var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "sw-wizard-step",
    {
      attrs: {
        title: _vm.$t("wizard.permissions.permissions"),
        description: _vm.$t("wizard.permissions.permission_desc"),
      },
    },
    [
      _vm.isFetching
        ? _c("base-loader", { attrs: { "show-bg-overlay": true } })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative" },
        [
          _vm._l(_vm.permissions, function (permission, index) {
            return _c(
              "div",
              { key: index, staticClass: "border border-gray-200" },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "grid grid-flow-row grid-cols-3 lg:gap-24 sm:gap-4",
                  },
                  [
                    _c("div", { staticClass: "col-span-2 p-3" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(permission.folder) +
                          "\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "p-3 text-right" }, [
                      permission.isSet
                        ? _c("span", {
                            staticClass:
                              "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-success",
                          })
                        : _c("span", {
                            staticClass:
                              "inline-block w-4 h-4 ml-3 mr-2 rounded-full bg-danger",
                          }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(permission.permission))]),
                    ]),
                  ]
                ),
              ]
            )
          }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFetching,
                  expression: "!isFetching",
                },
              ],
              staticClass: "mt-10",
              attrs: {
                loading: _vm.isLoading,
                disabled: _vm.isLoading,
                variant: "primary",
              },
              on: { click: _vm.next },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("wizard.continue")) + "\n      "
              ),
              _c("arrow-right-icon", { staticClass: "h-5 ml-2 -mr-1" }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }