var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "base-page",
    { staticClass: "customer-create" },
    [
      _c(
        "sw-page-header",
        { attrs: { title: _vm.$t("customers.title") } },
        [
          _c(
            "sw-breadcrumb",
            { attrs: { slot: "breadcrumbs" }, slot: "breadcrumbs" },
            [
              _c("sw-breadcrumb-item", {
                attrs: { title: _vm.$t("general.home"), to: "dashboard" },
              }),
              _vm._v(" "),
              _c("sw-breadcrumb-item", {
                attrs: {
                  title: _vm.$tc("customers.customer", 2),
                  to: "#",
                  active: "",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "sw-button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.totalCustomers,
                      expression: "totalCustomers",
                    },
                  ],
                  attrs: { size: "lg", variant: "primary-outline" },
                  on: { click: _vm.toggleFilter },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("general.filter")) +
                      "\n        "
                  ),
                  _c(_vm.filterIcon, {
                    tag: "component",
                    staticClass: "h-4 ml-1 -mr-1 font-bold",
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-button",
                {
                  staticClass: "ml-4",
                  attrs: {
                    "tag-name": "router-link",
                    to: "customers/create",
                    size: "lg",
                    variant: "primary",
                  },
                },
                [
                  _c("plus-sm-icon", {
                    staticClass: "h-6 mr-1 -ml-2 font-bold",
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("customers.new_customer")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "slide-y-up-transition",
        [
          _c(
            "sw-filter-wrapper",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showFilters,
                  expression: "showFilters",
                },
              ],
            },
            [
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2",
                  attrs: { label: _vm.$t("customers.display_name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "name", autocomplete: "off" },
                    model: {
                      value: _vm.filters.display_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "display_name", $$v)
                      },
                      expression: "filters.display_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.contact_name") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: {
                      type: "text",
                      name: "address_name",
                      autocomplete: "off",
                    },
                    model: {
                      value: _vm.filters.contact_name,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "contact_name", $$v)
                      },
                      expression: "filters.contact_name",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "sw-input-group",
                {
                  staticClass: "flex-1 mt-2 ml-0 lg:ml-6",
                  attrs: { label: _vm.$t("customers.phone") },
                },
                [
                  _c("sw-input", {
                    staticClass: "mt-2",
                    attrs: { type: "text", name: "phone", autocomplete: "off" },
                    model: {
                      value: _vm.filters.phone,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "phone", $$v)
                      },
                      expression: "filters.phone",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass:
                    "absolute text-sm leading-snug text-black cursor-pointer",
                  staticStyle: { top: "10px", right: "15px" },
                  on: { click: _vm.clearFilter },
                },
                [_vm._v(_vm._s(_vm.$t("general.clear_all")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "sw-empty-table-placeholder",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showEmptyScreen,
              expression: "showEmptyScreen",
            },
          ],
          attrs: {
            title: _vm.$t("customers.no_customers"),
            description: _vm.$t("customers.list_of_customers"),
          },
        },
        [
          _c("astronaut-icon", { staticClass: "mt-5 mb-4" }),
          _vm._v(" "),
          _c(
            "sw-button",
            {
              attrs: {
                slot: "actions",
                "tag-name": "router-link",
                to: "/admin/customers/create",
                size: "lg",
                variant: "primary-outline",
              },
              slot: "actions",
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("customers.add_new_customer")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showEmptyScreen,
              expression: "!showEmptyScreen",
            },
          ],
          staticClass: "relative table-container",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "relative flex items-center justify-between h-10 mt-5 border-b-2 border-gray-200 border-solid",
            },
            [
              _c("p", { staticClass: "text-sm" }, [
                _vm._v("\n        " + _vm._s(_vm.$t("general.showing")) + ": "),
                _c("b", [_vm._v(_vm._s(_vm.customers.length))]),
                _vm._v("\n        " + _vm._s(_vm.$t("general.of")) + " "),
                _c("b", [_vm._v(_vm._s(_vm.totalCustomers))]),
              ]),
              _vm._v(" "),
              _c(
                "sw-transition",
                { attrs: { type: "fade" } },
                [
                  _vm.selectedCustomers.length
                    ? _c(
                        "sw-dropdown",
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "flex block text-sm font-medium cursor-pointer select-none text-primary-400",
                              attrs: { slot: "activator" },
                              slot: "activator",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.actions")) +
                                  "\n            "
                              ),
                              _c("chevron-down-icon", { staticClass: "h-5" }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "sw-dropdown-item",
                            { on: { click: _vm.removeMultipleCustomers } },
                            [
                              _c("trash-icon", {
                                staticClass: "h-5 mr-3 text-gray-600",
                              }),
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.delete")) +
                                  "\n          "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "absolute z-10 items-center pl-4 mt-2 select-none md:mt-12",
            },
            [
              _c("sw-checkbox", {
                staticClass: "hidden md:inline",
                attrs: { variant: "primary", size: "sm" },
                on: { change: _vm.selectAllCustomers },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
              _vm._v(" "),
              _c("sw-checkbox", {
                staticClass: "md:hidden",
                attrs: {
                  label: _vm.$t("general.select_all"),
                  variant: "primary",
                  size: "sm",
                },
                on: { change: _vm.selectAllCustomers },
                model: {
                  value: _vm.selectAllFieldStatus,
                  callback: function ($$v) {
                    _vm.selectAllFieldStatus = $$v
                  },
                  expression: "selectAllFieldStatus",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-table-component",
            {
              ref: "table",
              attrs: {
                "show-filter": false,
                data: _vm.fetchData,
                "table-class": "table",
              },
            },
            [
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "no-click",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return _c(
                        "div",
                        { staticClass: "relative block" },
                        [
                          _c("sw-checkbox", {
                            attrs: {
                              id: row.id,
                              value: row.id,
                              variant: "primary",
                              size: "sm",
                            },
                            model: {
                              value: _vm.selectField,
                              callback: function ($$v) {
                                _vm.selectField = $$v
                              },
                              expression: "selectField",
                            },
                          }),
                        ],
                        1
                      )
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  filterable: true,
                  label: _vm.$t("customers.display_name"),
                  show: "name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.display_name"))),
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            staticClass: "font-medium text-primary-500",
                            attrs: { to: { path: `customers/${row.id}/view` } },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.name) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.contact_name"),
                  show: "contact_name",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("customers.contact_name"))),
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.contact_name
                                  ? row.contact_name
                                  : _vm.$t("customers.no_contact_name")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.phone"),
                  show: "phone",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [_vm._v(_vm._s(_vm.$t("customers.phone")))]),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                row.phone
                                  ? row.phone
                                  : _vm.$t("customers.no_contact")
                              ) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.amount_due"),
                  show: "due_amount",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("customers.amount_due")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$utils.formatMoney(
                                row.due_amount,
                                row.currency
                              )
                            ),
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: true,
                  label: _vm.$t("customers.added_on"),
                  "sort-as": "created_at",
                  show: "formattedCreatedAt",
                },
              }),
              _vm._v(" "),
              _c("sw-table-column", {
                attrs: {
                  sortable: false,
                  filterable: false,
                  "cell-class": "action-dropdown",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (row) {
                      return [
                        _c("span", [
                          _vm._v(
                            " " + _vm._s(_vm.$t("customers.action")) + " "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "sw-dropdown",
                          [
                            _c("dot-icon", {
                              attrs: { slot: "activator" },
                              slot: "activator",
                            }),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  to: `customers/${row.id}/edit`,
                                  "tag-name": "router-link",
                                },
                              },
                              [
                                _c("pencil-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.edit")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                attrs: {
                                  to: `customers/${row.id}/view`,
                                  "tag-name": "router-link",
                                },
                              },
                              [
                                _c("eye-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.view")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "sw-dropdown-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.removeCustomer(row.id)
                                  },
                                },
                              },
                              [
                                _c("trash-icon", {
                                  staticClass: "h-5 mr-3 text-gray-600",
                                }),
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("general.delete")) +
                                    "\n            "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }