var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    {
      on: {
        submit: function ($event) {
          $event.preventDefault()
          return _vm.saveEmailConfig.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "grid gap-6 sm:grid-col-1 md:grid-cols-2" },
        [
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.driver"),
                error: _vm.driverError,
                required: "",
              },
            },
            [
              _c("sw-select", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_driver.$error,
                  options: _vm.mailDrivers,
                  "allow-empty": false,
                  searchable: true,
                  "show-labels": false,
                },
                on: { input: _vm.onChangeDriver },
                model: {
                  value: _vm.mailConfigData.mail_driver,
                  callback: function ($$v) {
                    _vm.$set(_vm.mailConfigData, "mail_driver", $$v)
                  },
                  expression: "mailConfigData.mail_driver",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.mailgun_domain"),
                error: _vm.domainError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_mailgun_domain.$error,
                  type: "text",
                  name: "mailgun_domain",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.mail_mailgun_domain.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.mail_mailgun_domain,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_domain",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_mailgun_domain",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.mailgun_secret"),
                error: _vm.secretError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_mailgun_secret.$error,
                  type: _vm.getInputType,
                  name: "mailgun_secret",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.mail_mailgun_secret.$touch()
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "rightIcon",
                    fn: function () {
                      return [
                        _vm.isShowPassword
                          ? _c("eye-off-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                              on: {
                                click: function ($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                },
                              },
                            })
                          : _c("eye-icon", {
                              staticClass:
                                "w-5 h-5 mr-1 text-gray-500 cursor-pointer",
                              on: {
                                click: function ($event) {
                                  _vm.isShowPassword = !_vm.isShowPassword
                                },
                              },
                            }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.mailConfigData.mail_mailgun_secret,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_secret",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_mailgun_secret",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.mailgun_endpoint"),
                error: _vm.endpointError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.mail_mailgun_endpoint.$error,
                  type: "text",
                  name: "mailgun_endpoint",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.mail_mailgun_endpoint.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.mail_mailgun_endpoint,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "mail_mailgun_endpoint",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.mail_mailgun_endpoint",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.from_mail"),
                error: _vm.fromEmailError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_mail.$error,
                  type: "text",
                  name: "from_mail",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.from_mail.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.from_mail,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_mail",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_mail",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "sw-input-group",
            {
              attrs: {
                label: _vm.$t("settings.mail.from_name"),
                error: _vm.fromNameError,
                required: "",
              },
            },
            [
              _c("sw-input", {
                staticClass: "mt-2",
                attrs: {
                  invalid: _vm.$v.mailConfigData.from_name.$error,
                  type: "text",
                  name: "from_name",
                },
                on: {
                  input: function ($event) {
                    return _vm.$v.mailConfigData.from_name.$touch()
                  },
                },
                model: {
                  value: _vm.mailConfigData.from_name,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.mailConfigData,
                      "from_name",
                      typeof $$v === "string" ? $$v.trim() : $$v
                    )
                  },
                  expression: "mailConfigData.from_name",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex my-10" },
        [
          _c(
            "sw-button",
            {
              attrs: {
                disabled: _vm.loading,
                loading: _vm.loading,
                variant: "primary",
                type: "submit",
              },
            },
            [
              _c("save-icon", { staticClass: "mr-2" }),
              _vm._v("\n      " + _vm._s(_vm.$t("general.save")) + "\n    "),
            ],
            1
          ),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }